.reportMainCont {
  /* border: 1px solid #424242; */
  border-radius: 4px;
  padding: 20px;
  margin-top: 40px;
  /* background-image: ; */
}
.basicDetailsBox {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important; */
  box-shadow: 0px 4px 12px 0px #b4b4b440;
  padding: 18px 18px !important;
}

.sectionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  color: #ffffff;
  font-weight: 600;
  background-color: #424242;
  border-radius: 4px;
  margin-top: 25px;
}
.inputBox {
  /* color: #ffffff; */
  font-weight: 500;
  margin-top: 12px;
  color: #3b3939;
  line-height: 16.34px;
  font-size: 12px;
  /* text-align: center; */
  /* color: aqua; */
  width: 20%;
  margin-left: 6%;
}
.inputBoxCrop {
  font-weight: 500;
  margin-top: 12px;
  color: #3b3939;
  line-height: 16.34px;
  font-size: 12px;
  margin-left: 6%;
}
.input {
  /* padding: 2px 8px; */
  border-radius: 2px;
  margin-top: 3px;
  font-weight: 400;
  font-family: poppins;
}
.inputcrop {
  border-radius: 2px;
  margin-top: 3px;
  font-weight: 400;
  font-family: poppins;
}
.soilPropertiesBox {
  /* border: 1px solid #d9d9d9; */
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  padding: 10px 10px 20px 10px;
  margin-top: 10px;
  margin-left: 4%;
}
.highchart {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  /* border: 1px solid #d9d9d9; */
}
.recomendCrop {
  /* font-size: larger; */
  text-transform: capitalize;
}
.headings {
  /* font-size: 18px;
  font-weight: 700; */
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #6f9300 !important;
}
.nutrientsheadings {
  /* font-size: 15px;
  font-weight: 700; */
  /* color: rgb(51, 51, 51) !important;
  font-size: 18px !important;
  fill: rgb(51, 51, 51) !important;
  font-weight: 400 !important;
  font-family: poppins !important; */
  /* font-weight: 600; */
  font-weight: 700;
  font-size: 18px;
  line-height: 24.51px;
  color: #3b3939;
}
.back-drop-text-2 {
  color: #424242;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.1rem;
}
.loader-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailhead {
  color: #394d09 !important ;
  font-size: 30px !important ;
  font-weight: 600 !important ;
  font-family: Poppins !important ;
  margin-top: 3% !important;
}
.ownershipbox {
  color: #394d09 !important ;
  font-size: 30px !important ;
  font-weight: 600 !important ;
  font-family: Poppins !important ;
  margin-top: 3% !important;
  margin-left: 3%;
}
.farmhead {
  /* font-family: Open Sans; */
  font-size: 50px !important;
  font-weight: 700 !important;
  line-height: 68px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.healthChartDetails {
  font-size: 16px;
  font-family: poppins !important;
  font-weight: 600;
  /* color: rgb(88, 87, 87); */
  color: #3b3939;
  line-height: 21.79px;
}
.rabikharif {
  width: 35%;
  padding: 12px;
}

.seasoncrop {
  font-family: poppins !important;
  color: #3b3939 !important;
  margin-left: 5% !important;
  margin-bottom: 4% !important;
}
.seasonName {
  padding: 16px 13px;
  font-weight: 700 !important;
  text-transform: capitalize;
  color: #6f9300 !important ;
  font-size: 20px !important;
}
.hindiDetailsBox {
  box-shadow: 0px 4px 12px 0px #b4b4b440;
  padding: 18px 18px !important;
  background: #8fac37;
  width: 91%;
  margin-left: 3%;
}
.hindiDetails {
  font-weight: 400;
  font-family: poppins;
  color: #ffffff;
  text-align: center;
}
.basicTopBox {
  display: flex;
}
.basic-detail-key {
  font-weight: 600;
}
.cropkey {
  font-weight: 600;
  color: #6f9300;
}
.featureBox {
  background: #f2ffe4 !important ;
}
.rabikharif {
  /* width: "40%" !important; */
}
.cropBox {
  width: 50% !important;
}
.headBotomLine {
  border-bottom: 3px dashed #394d09;
  width: 91%;
  margin-left: 3%;
}
.basicDetailHead {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}
.detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  position: relative;
  bottom: 14px;
}
.farmrepo {
  font-size: 100px !important;
  font-weight: 600 !important;
  line-height: 136px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #ffffff !important;
  margin-left: 4%;
}

@import "https://unpkg.com/leaflet-draw@latest/dist/leaflet.draw-src.css";
.rightSider {
  background-color: #212121 !important;
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.mapHeader {
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
}
.trigger {
  padding: 0 10px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  /* transition: color 0.3s; */
  /* color: #ffffff; */
  height: 32px;
}
.cardsHead {
  font-size: 14px;
  color: #3b3939;
  width: -webkit-fill-available;
}
.cardSpantag {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.farmersFarmName {
  color: #ffffff;
}
.colapsefarmersCardDetails {
  font-size: 11px;
  display: flex;
}
.colapsefarmersCardDetails2 {
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 4px 8px;
  margin-top: 4px;
  font-size: 11px;
}
.colapsespantag {
  font-size: 11px;
  font-weight: 500;
  width: 39%;
  margin-top: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0px 5px;
  background: white;
  color: black;
  border-radius: 2px;
}
.colapsefarmersDetail {
  width: 61%;
  margin-left: 6px;
  margin-top: 10px;
  padding: 0px 5px;
  background: white;
  color: black;
  font-size: 11px;
  border-radius: 2px;
}
.back-drop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  flex-direction: column;
}
.back-drop-text {
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.1rem;
}
.typewriter p {
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.1rem;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typewriter 5s steps(7) infinite, blinking-cursor 0.8s infinite;
  animation-fill-mode: forwards;
}
.inputField {
  margin: 8px 8px 8px 0px !important;
}
.ant-collapse-expand-icon {
  color: #ffffff;
}
.ant-collapse-header {
  padding: 5px 10px !important;
}
.ant-collapse-content-box {
  padding: 6px 10px !important;
}
.revenueBox {
  display: flex;
  width: 62%;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%;
}

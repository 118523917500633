.heading {
  font-size: 30px;
}
.mainCont {
  /* background-image: url("https://agribhumi.s3.ap-south-1.amazonaws.com/assets/images/advisoryicon.jpeg"); */
  background-size: 268px;
  padding: 10%;
  background-color: #212121;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
}
.loginCard {
  background-color: #fbf4e9;
  padding: 4%;
  border-radius: 8px;
  background-color: #424242;
  width: 80%;
  height: auto;
}
.loginButton {
  margin: 2% 0%;
  border-radius: 5px;
  width: 120px;
  height: 30px;
  box-shadow: none;
  outline: none;
  background-color: #ffffff;
  border-color: #ffffff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mobileHeading {
  font-size: 20px;
  color: #ffffff !important;
  margin-bottom: 12px;
  font-weight: 500;
}
.helperText {
  font-size: 12px;
  line-height: 1.3rem;
  color: #ffffff !important;
}
.changeNumber {
  text-decoration: underline;
  font-weight: 600;
  margin-left: 2px;
  font-size: 15px;
  cursor: pointer;
}
.inputCont {
  display: flex;
  width: 50%;
}
.textInput {
  margin-bottom: 15px !important;
  margin-top: 10px !important;
}

.heading {
  font-size: 30px;
}
/* .mainCont {
  background-image: url("https://agribhumi.s3.ap-south-1.amazonaws.com/assets/images/login-bg.svg");
  background-size: 268px;
  padding: 10%;
  background-color: #212121;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
} */
.loginCard {
  background-color: #fbf4e9;
  padding: 4%;
  border-radius: 8px;
  background-color: #424242;
  width: 80%;
  height: auto;
  height: 375px;
}
.cardTitle {
  font-size: 30px;
  font-weight: 500;
  color: #ffffff !important;
  margin-bottom: 10px;
}
.enterNum {
  margin: 2% 0%;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff !important;
}
.enterNum2 {
  margin: 2% 0%;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff !important;
}
.loginButton {
  margin: 2% 0%;
  border-radius: 5px !important;
  width: 120px;
  height: 30px !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  font-weight: 600 !important;
  /* display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; */
}
.loginButton:hover {
  color: #000000;
}
.mobileHeading {
  font-size: 20px;
  color: #ffffff !important;
  margin-bottom: 12px;
  font-weight: 500;
}
.helperText {
  font-size: 12px;
  line-height: 1.3rem;
  color: #ffffff !important;
  margin-top: 5%;
}
.changeNumber {
  text-decoration: underline;
  font-weight: 600;
  margin-left: 2px;
  font-size: 15px;
  cursor: pointer;
}
.inputCont {
  display: flex;
  width: 50%;
}
.textInput {
  margin-bottom: 15px !important;
  margin-top: 10px !important;
}
.forgotPassCont {
  display: flex;
  width: 50%;
  justify-content: end;
  margin-top: 2%;
}
.forgotPass {
  color: #ffffff !important;
  font-weight: 600;
  text-decoration: underline;
}
.loginBtn {
  background-color: #ed1849 !important;
  border-color: #ed1849 !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  width: 38%;
  height: 45px !important;
  margin-top: 5%;
  margin-bottom: 10px;
}
.emptyMessageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.accountHoldText {
  color: #ffffff !important;
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
}

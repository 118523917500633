.navLink {
  color: #ffffff !important;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}
.selectedNavLinkBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #424242;
  cursor: pointer;
}
.navLinkBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.drawer {
  min-height: calc(100vh - 64px);
  background-color: #212121 !important;
}

.dateElementBox {
  padding: 12px;
  border-right: 1px solid #ffffff;
  cursor: pointer;
  padding: 0px 12px;
}
.logOutBtn {
  margin-left: 26%;
  margin-top: 12%;
}

.css-dev-only-do-not-override-1n7nwfa.ant-modal .ant-modal-content {
  /* background-color: #212121 !important; */
}
.cropcol {
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -moz-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #D5ECA6 !important;
  font-weight: 600;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.cropcol .typography {
  padding-left: 0;
  color: #212121;
  font-size: 14px;
}
.typographyContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 576px) {
  .cropcol {
    flex-direction: column;
    gap: 4px;
    padding: 8px;
  }
  .cropcol .typography {
    font-size: 12px;
    text-align: center;
  }
  .typographyContainer {
    flex-direction: column;
    gap: 4px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .cropcol {
    flex-direction: row;
    gap: 6px;
    padding: 10px;
  }
  .cropcol .typography {
    font-size: 13px;
  }
  .typographyContainer {
    gap: 6px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .cropcol {
    flex-direction: row;
    gap: 8px;
    padding: 12px;
  }
  .cropcol .typography {
    font-size: 14px;
  }
  .typographyContainer {
    gap: 8px;
  }
}
@media (min-width: 992px) {
  .cropcol {
    flex-direction: row;
    gap: 10px;
    padding: 14px;
  }
  .cropcol .typography {
    font-size: 15px;
  }
  .typographyContainer {
    gap: 10px;
  }
}
.recomndbox {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
}
.lowerdiv {
  border: 2px solid #3028280f !important;
}
.upperdiv {
  border: 2px solid #3028280f !important;
}
.agrlogo {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}
.soil-data-drawer-txt {
  border: 0.1px solid rgb(234, 220, 220);
  padding: 5px;
    font-size: 12px !important;
    color: #ffffff !important;
    margin-top: 2rem;
    text-align: justify;
    width: 100%; /* Ensure the container takes up the full width of its parent */
    line-height: 1.5 !important; /* Adjust the line height for better readability */
    white-space: normal; /* Ensure text wraps properly */
    overflow-wrap: break-word; /* Break long words if needed */
    word-break: break-word !important; /* Break long words if needed */
    box-sizing: border-box !important; /* Include padding and border in the element's total width and height */
  }
  .soil-data-map-heading{
    font-size: 1rem !important;
    color: #dbdef9 !important;
    font-weight: bold;
    text-align: center;
  }
  .soil-data-content-map{
    /* font-style: italic !important; */
    font-size: 12px !important;
    color: #ffffff !important;
    margin-top: 2rem;
    text-align: justify;
    width: 100%; /* Ensure the container takes up the full width of its parent */
    line-height: 1.5 !important; /* Adjust the line height for better readability */
    white-space: normal; /* Ensure text wraps properly */
    overflow-wrap: break-word; /* Break long words if needed */
    word-break: break-word !important; /* Break long words if needed */
    box-sizing: border-box !important; /* Include padding and border in the element's total width and height */
  }
/* #map {
  height: calc(100vh - 157px);
} */
@import "https://unpkg.com/leaflet-draw@latest/dist/leaflet.draw-src.css";

.mapHeader {
  background-color: #212121;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0px 12px;
  position: relative;
}

.resetBtn {
  background-color: #424242 !important;
  border-color: #424242 !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  margin-right: 10px;
  margin-left: 4%;
}

.dropdown {
  margin-left: 10px !important;
  width: 145px !important;
}

.mapFooter {
  background-color: #212121;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0px 12px;
  justify-content: space-between;
}

.dateSelector {
  margin-left: 10px !important;
}

.statChip {
  height: 25px;
  font-weight: 600;
}

.label {
  background-color: transparent !important;
  font-weight: bolder !important;
  color: #000000 !important;
  border-color: transparent !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.rightSider {
  background-color: #212121 !important;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.rightSiderCont {
  padding: 20px;
}

.siderSection {
  margin-bottom: 20px;
}

.siderSectionTitle {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.cropInfoCard {
  background-color: #ffffff;
  border-radius: 2px;
}

.cropInfoCardSection {
  display: flex;
  /* justify-content: space-between; */
  padding: 6px;
}

.cropInfoCardText {
  font-size: 12px;
}

.trigger {
  padding: 0 10px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  /* transition: color 0.3s; */
  /* color: #ffffff; */
  height: 32px;
}

.legend {
  color: #fff;
  width: auto;
  max-width: 300px;
  min-height: 42px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  justify-content: center;
  padding: 12px 25px 12px 15px;
}

.ant-modal-header {
  background-color: #212121 !important;
  border-bottom: none;
}

.ant-modal-close-icon {
  /* color: #ffffff !important; */
  color: #000000 !important;
}

.crop-history-dialog-header {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
  border-radius: 4px;
  padding: 5px 0px;
  font-weight: 600;
  margin-top: 10px;
}

.crop-history-card {
  padding: 20px 0px;
}

.crop-history-card-upper {
  background-color: #ffffff;
  color: #424242;
  font-weight: 600;
  border-radius: 4px 4px 0px 0px;
  padding: 10px;
  border: 1px solid #d9d9d9;
}

.crop-history-card-bottom {
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  padding: 10px;
}

.crop-history-card-detail {
  margin: 0;
}

.lulcLegend {
  color: #000000;
  width: auto;
  min-height: 20px;
  max-height: 92px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 0;
  background: #ffffff;
}

.lulcInfo {
  padding: 2px 15px;
  font: 14px/16px Arial, Helvetica, sans-serif;

  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

.lulcCont {
  display: flex;
}

.lulcIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

/* .logOutBtn {
  margin-left: 0%;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background-color: #9e9e9ef2;
  color: #000107;
  border: none;
  text-transform: capitalize;
} */
/* .searchBtn{
  margin-left: 4%;
} */
.farms {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #4aab28;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
}

.farms {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #4aab28;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 20px;
  margin-top: 9%;
}

.farmerData {
  color: aliceblue;
}

.farmerdetails {
  display: flex;
  text-align: center;
  flex-direction: column;
  /* margin-top: 8%; */
  align-items: center;
}

/*  Modal */

.modalcontainer {
  background-color: #303030;
}

.innerselect {
  display: flex;
  margin-top: 4%;
  justify-content: space-between;
  align-items: center;
  width: 721px;
}

.farmbtn {
  width: 153px;
  height: 30px;
  /* left: 933px;
  top: 255px; */
  background: #4aab28;
  border-radius: 2px;
}

.modelInnerDiv {
}

.bottomDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  padding-bottom: 4%;
}

.bottomtext {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #fcfcfc;
}

.innerModalContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
}

.npkcrop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  justify-content: space-between;
  text-align: center;
}

.cropname {
  width: 204px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
}

.cropcol {
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 26px;
  display: flex;
}

.upperdiv {
  display: flex;
  justify-content: space-evenly;
}

.lowerdiv {
  display: flex;
  justify-content: space-evenly;
}

.recomndbox {
  width: 308px;
  height: 62px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* border-radius: 10px 10px 0px 0px; */
  /* text-align: center; */
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #303030;
}

.values {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  flex-direction: column;
}

.recomnValues {
  margin-top: 7%;
  margin-bottom: 11%;
  padding-left: 7%;
}

/* FARMER SCORE CARD MODAL */
.nav {
  /* background-color: #ffffff; */
}

.topHead {
  border-radius: 8px;
  text-align: center;
  margin-top: 2%;
  padding: 3px;
  text-transform: capitalize;
  background: gray;
  font-size: x-large;
}

.middlehead {
  /* background-color: #ffffff; */
  margin-top: 4px;
  border-radius: 8px;
  text-align: center;
  margin-top: 4%;
  padding: 5px;
  text-transform: capitalize;
  background: gray;
  font-size: x-large;
}

.details {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.keybox {
  background-color: #ffffff;
  margin-bottom: 4%;
  text-transform: capitalize;
  padding-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 60%;
}
.valbox {
  background-color: #ffffff;
  margin-bottom: 4%;
  text-transform: capitalize;
  padding-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
}
.farmervalues {
  /* margin-left: 4%; */
  width: 35%;
  display: flex;
  flex-direction: column;
}
.farmerkeys {
  margin-left: 1%;
  width: 35%;
  display: flex;
  flex-direction: column;
}
.landdetails {
  margin-top: 4%;
}
.landhead {
  text-align: center;
  margin-bottom: 3%;
  text-transform: capitalize;
  padding: 5px;
  text-transform: capitalize;
  background: gray;
  font-size: x-large;
}
.farmlanddetails {
  margin-top: 4%;
}
.profitandloss {
  margin-top: 4%;
}
.profitestimatetable {
  margin-top: 4%;
}
.riskprofile {
  margin-top: 4%;
}
.crophistrodiv {
  margin-top: 3%;
}
.crophistryhead {
  background: grey;
}
.back-drop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  flex-direction: column;
}
.back-drop-text {
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.1rem;
}
.back-drop-text2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1rem;
}
.typewriter p {
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.1rem;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typewriter 5s steps(7) infinite, blinking-cursor 0.8s infinite;
  animation-fill-mode: forwards;
}
.typewriter2 p {
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1rem;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typewriter 5s steps(7) infinite, blinking-cursor 0.8s infinite;
  animation-fill-mode: forwards;
}
@keyframes typewriter {
  0% {
    width: 0;
  }
  25% {
    width: 70%;
  }
  48% {
    width: 100%;
  }
  50% {
    width: 100%;
  }
  55% {
    width: 100%;
  }
  75% {
    width: 70%;
  }
  85% {
    width: 30%;
  }
  100% {
    width: 0;
  }
}

@keyframes blinking-cursor {
  from {
    border-color: transparent;
  }
  to {
    border-color: #f9d566;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
/* FARMS SECTION */
.farmsCards {
  /* background-color: black; */
  /* background-color: rgb(48, 48, 48); */
  border: 1px solid white;
  border-radius: 6px;
  padding: 6%;
  margin-bottom: 6%;
}
.farmersCardDetails {
  color: #ffffff !important ;
  font-size: 11px;
  display: flex;
}
.spantag {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid white;
  width: 30%;
}
.cardsHead {
  font-size: 14px;
  color: #3b3939;
  width: -webkit-fill-available;
}
.cardSpantag {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}
.farmersDetail {
  color: #ffffff !important ;
  width: 78%;
  border: 1px solid white;
}
.colapsespantag {
  font-size: 12px;
  font-weight: 500;
  width: 39%;
  margin-top: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0px 5px;
  background: white;
  color: black;
}

.colapsefarmersDetail {
  width: 61%;
  margin-left: 6px;
  margin-top: 10px;
  padding: 0px 5px;
  background: white;
  color: black;
}
.colapsefarmersCardDetails {
  font-size: 11px;
  display: flex;
}
.farmersFarmName {
  color: #ffffff;
}
.ant-collapse-content-box {
  background-color: rgb(48, 48, 48);
}
.searchingFarms {
}
.showFarmCheck {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advisryImg {
  width: -webkit-fill-available;
  height: inherit;
  margin-right: 1px;
}
.advisryImg:hover {
  transform: scale(1.8);
}
.ant-select-arrow-loading {
  color: #424242 !important;
}
.pagin {
  /* background-attachment: fixed; */
  background-color: #000000;
}
.ant-pagination {
  background-color: #212121;
}
.ant-pagination-mini {
  background-color: #212121;
}
.ant-pagination-item {
  /* background-color: #ffffff; */
}
.ant-pagination .ant-pagination-item-active {
  /* background-color: #ffffff !important; */
}
.ant-pagination-item .ant-pagination-item {
  background-color: #ffffff !important;
  margin-right: 5px !important;
  color: #fcfcfc;
}
.ant-pagination-item .ant-pagination-item-link {
  color: #fcfcfc;
}
#sidebar {
  /* transition: ease-in-out 2s; */
}
.mainYieldContainer {
  /* border: 1px solid #ffffff; */
  text-align: center;
  border-radius: 6px;
  background-color: #ffffff;
}
.yieldHead {
  margin-top: 5%;
}
.seasonName {
  padding: 16px 13px;
  font-weight: 700 !important;
  text-transform: capitalize;
  color: #6f9300 !important ;
  font-size: 20px !important;
  border-bottom: dotted;
}
.agrlogo {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.healthIndex {
  text-align: center;
}
.healthIndexHead {
  font-weight: 700;
  font-size: 16px;
}

.navbar {
  background-color: #ffffff;
  height: 100%;
}
.logOutBtn {
  color: black;
}
.ant-modal-content {
  padding: 0px 0px 0px 0px;
}

.ant-modal-content {
  padding: 0;
}

.weatherinfo {
  display: flex;
  flex-direction: column;
  line-height: initial;
  margin-top: 17px;
  color: #3b3939;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  margin-right: 12px;
}
.atmosphr {
  display: flex;
  align-items: center;
}
.weathercontainer {
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-end;
  cursor: pointer;
  padding-bottom: 14px;
  color: #ffffff;
  align-items: center;
}
.wether_details {
  color: #ffffff;
}
.nthchild {
  border-right: 1px solid #d2d2d2;
  padding: 0px 10px;
}
.iconimg {
  margin-right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  border-radius: 8px;
}
.weather {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #3b3939;
  margin-right: 6px;
}

/* modal css */

.topcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  /* padding-bottom: 23px; */
  padding-bottom: 12px;
  border-bottom: 1px solid #ffffff;
  border-radius: 16px;
}
.temp_box {
  display: flex;
  align-items: center;
}
.img {
  display: flex;
  align-items: center;
}
.container-outer {
  display: flex;
  overflow-x: scroll;
  height: 230px;
  margin-top: 5%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
.container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px dashed #ffffff;
  margin-left: 1%;
  padding: 3%;
}

.weeklyWeather {
  cursor: pointer;
  border: 2px solid #ffffff;
  width: 12%;
  height: 90px;
  text-align: center;
  border-radius: 10px;
}
.weeklyContainer {
  display: flex;
  margin-top: 1%;
  justify-content: space-around;
}
.wetherCondition {
  margin-top: 14%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.temp_img {
  width: 28px;
  height: 31px;
}
.max_min_temp {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}
.todaysDate {
  text-transform: capitalize;
  font-size: medium;
  color: #ffffff;
}
.weeklytime {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.temp_desc {
  display: flex;
  align-items: center;
  width: max-content;
}
.wether_details {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.humidity {
  display: flex;
  margin-top: 5%;
}
.midbox {
  display: flex;
  justify-content: space-between;
}
.scroldiv {
  display: flex;
  align-items: center;
}
.hourleydata {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 0%;
  border-radius: 13px;
}
.weatherParams {
  font-size: 10px;
}
.ab-logo-cont {
  display: flex;
  align-items: end;
  height: 100%;
  padding: 0px 0px 10px 10px;
}

.topRight {
  margin-left: 2%;
}
.humidity {
  margin-right: 3%;
}

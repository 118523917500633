/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-modal .ant-modal-content {
  background-color: #ffffff;
} */

.topHeading {
  text-align: center;
  margin-top: 2%;
  padding: 3px;
  text-transform: capitalize;
  font-size: 20px;
  border-radius: 4px;
  background-color: #424242;
}
.topHeadingText {
  color: #ffffff !important;
  font-weight: 600;
}
.middlehead {
  text-align: center;
  margin-top: 4%;
  padding: 5px;
  text-transform: capitalize;
  background-color: #424242;
  border-radius: 4px;
}

.details {
  display: flex;

  margin-top: 2%;
  padding: 0px 20px;
}

.keybox {
  background-color: #ffffff;
  padding: 5px 10px;
  width: 90%;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
}

.valbox {
  background-color: #ffffff;
  padding: 5px 10px;
  width: 90%;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
}
.farmervalues {
  width: 64%;
  display: flex;
  flex-direction: column;
}
.farmerkeys {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.landdetails {
  margin-top: 4%;
}
.landhead {
  text-align: center;
  margin-top: 4%;
  padding: 5px;
  text-transform: capitalize;
  background-color: #424242;
  border-radius: 4px;
}
.farmlanddetails {
  margin-top: 4%;
}
.profitandloss {
  margin-top: 4%;
}
.profitestimatetable {
  margin-top: 4%;
}
.riskprofile {
  margin-top: 4%;
}
.scoreCardLogo {
  width: 120px;
}
.sectionHeading {
  font-size: 16px;
  color: #ffffff !important;
  font-weight: 600;
}
.sectionHeading2 {
  font-size: 16px;
  color: #424242 !important;
  font-weight: 600;
}
.white-text {
  color: #424242 !important;
  font-weight: 500;
}
.landtable {
  margin-top: 20px;
}
.ant-table-thead .ant-table-cell {
  background-color: #f2f2f2;
  color: #424242;
  padding: 12px 12px;
  border-bottom: 1px solid #d9d9d9;
}
.ant-table-tbody .ant-table-cell {
  background-color: #ffffff;
  color: #424242;
  padding: 12px 12px;
  border-bottom: none;
}
.ant-table .table {
  border-spacing: 1px !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #616161 !important;
}
.tableHead {
  display: flex;
  align-items: center;
  background-color: #424242;
}
.cropHistorySection {
  padding: 5px;
  text-transform: capitalize;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.cropHistoryTableHead {
  display: flex;
  align-items: center;
}
.c-t-head-cell {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  border: 1px solid #ffffff;
  color: #424242;
  text-align: center;
  height: 46px;
  border: 1px solid #d9d9d9;
}
.c-t-body-cell {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  border: 1px solid #ffffff;
  color: #424242;
  text-align: center;
  height: 46px;
  border: 1px solid #d9d9d9;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #ffffff !important;
}
.farmlandInfo {
  width: fit-content;
  padding: 2px 12px;
  background-color: #424242;
  margin-bottom: 4px;
  border-radius: 4px;
}
.landtable2 {
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 18px;
}
.cardMainCont {
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}
.dateScrollCont {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dateScrollCont::-webkit-scrollbar {
  display: none;
}
.agrlogo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

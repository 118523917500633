/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-modal .ant-modal-content {
  background-color: #212121;
}*/
.crop-history-dialog-header {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
  padding: 5px 0px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212121; */
}
.crop-history-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  color: #212121;
}
.histHead {
  color: #212121;
  font-size: "40px";
}
.histVal {
  color: rgb(88, 87, 87);
  font-weight: 400;
}
.agrlogo {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.cropBox {
  width: 50% !important;
}
.rabikharif {
  width: 35%;
  padding: 12px;
}
.seasonName {
  padding: 16px 13px;
  font-weight: 700 !important;
  text-transform: capitalize;
  color: #6f9300 !important ;
  font-size: 20px !important;
}
.seasoncrop {
  font-family: poppins !important;
  color: #3b3939 !important;
  margin-left: 5% !important;
  margin-bottom: 4% !important;
}

/* .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  position: fixed;
} */
.logo {
  padding-left: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.lognBtnDiv {
  /* padding-right: 6%; */
  margin-left: 61%;
  margin-top: 1%;
}
.lognBtn {
  background: #ed1849 !important;
  border-radius: 6px;
  color: #ffffff !important;
  width: 100px;
}
.topHead {
  font-weight: 500 !important;
  font-size: x-large !important;
  line-height: 40px;
  color: #ffffff !important;
}
.text {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}
.middlelefttext {
  font-weight: 400;
  font-size: 42px;
  line-height: 46px;
  color: #ffffff !important;
}
.leftcardmidleText {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 3%;
}
.spanText {
  color: #ed1849;
}
.leftText {
  width: 425px;
}
.topDiv {
  display: flex;
  justify-content: space-between;
  /* margin-top: 5%; */
  padding: 2% 6%;
  align-items: center;
}
.topImg {
  width: 400px;
  height: 300px;
}
.leftdivBotomText {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 18px;
  color: #ffffff !important;
  margin-top: 3%;
}
.weatherimg {
  width: 100%;
}
.weatherimgdiv {
  margin-top: 5%;
}
.middlerightdiv {
  background-color: #464545;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 60%;
  padding: 1%;
  margin-left: 2%;
  margin-top: 1%;
  height: fit-content;
}
.middlerightSide {
  width: 60%;
  padding: 1%;
}
.leftdivBotomdiv {
  /* width: 74%; */
}

.icons {
  /* width: 0px;
  height: 0px; */
}
.imgicn {
  width: 25px;
  height: 25px;
}
.icnname {
  font-size: 10px !important;
  color: #ffffff !important;
  text-transform: lowercase !important;
  font-weight: 100;
  line-height: 18px;
  margin-top: 5px;
  /* width: min-content; */
}
.righttop {
  background-color: #464545;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1%;
  padding: 1%;
  height: 32%;
  display: flex;
}
.middlediv {
  display: flex;
  padding: 0% 5%;
  margin-top: 2%;
}
.rightbottom {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
.bottomleft {
  background-color: #464545;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 49%;
  padding: 1%;
  /* height: fit-content; */
}
.bottomright {
  background-color: #464545;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 49%;
  padding: 2%;
  /* height: fit-content; */
}
.middleleftSide {
  width: 65%;
  margin-left: 15px;
}
.bottmtext {
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 38px !important;
  line-height: 65px !important;
}
.cardtxt {
  color: #ffffff !important;
  padding: 3% !important;
  font-size: initial !important;
}
.botmcard {
  width: 22%;
  border: 8px solid #424242;
}
.crdimg {
  width: -webkit-fill-available;
}
.bottomdiv {
  /* margin-top: 5%; */
}

/* ICONS  */

.middlelefticons {
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.middletoprighticons {
}
.middleleftdiv {
  background-color: #464545;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 33%;
  padding: 2%;
  margin-left: 2%;
  margin-top: 1.6%;
  height: fit-content;
}
.middlerighttophead {
  font-weight: 400;
  font-size: 33px;
  line-height: 38px;
  color: #ffffff;
}
.middlerightbotmtext {
  margin-top: 11%;
}
.midRighTopDiv {
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.midRighTopImg {
  height: 66%;
  margin-top: 5%;
  margin-left: 22%;
}
.midRighTopImg {
  width: 25px;
  height: 25px;
}
.midRighTopNam {
  font-size: 10px;
  color: #ffffff;
  font-weight: 100;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 92%;
}
